@import 'https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.step {
  text-align: center;
  margin: 20px;
}

.step-image {
  height: 100px; /* Adjust as needed */
  margin-bottom: 10px;
}

.connector {
  width: 50px; /* Adjust as needed */
  height: 2px;
  background-color: black;
  margin: 0 20px;
}

.light-gradient-text {
    --text-gradient: linear-gradient(180deg,#555,#000);
    background: var(--text-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

.light-gradient-text::selection {
    background-clip: unset;
    -webkit-text-fill-color: initial
}

.gradient-text {
  background: linear-gradient(180deg, #fff, #adadad);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

.logoSection {
  align-items: flex-start;
}
.logoSection__a {
  font-size: 32px;
}
.logoSection__span {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 14px;
  margin-left: 8px;
}
.logoSection__logoOrderix {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 60px;
  padding-left: 5px;
}
.logoSection__logoOrderix img {
  position: absolute;
  top: -5px;
}
h1 {
  line-height: 1.1;
}

.mobileWrapper {
  display: flex;
  background-color: red;
  width: 400px;
  height: 400px;
  background: url(../public/phone_035.jpg) center no-repeat;
  background-size: cover;
}